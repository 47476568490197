import React from "react";
import SectionTitle from "../components/sectiontitle";
import Layout from "../components/layout";
import SEO from "../components/seo";
// import Contact from "../components/contact";

export default function() {
    return (
        <Layout>
            <SEO lang="en" title="Contact" />
            <section id="contact" className="container" style={{ minHeight: "600px" }}>
                <div className="section-title">
                    <SectionTitle title="Contact" />
                        <p className="text-quaternary">
                        Looking forward to hearing from you. 
                        </p>
                        <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
                            <input type="hidden" name="form-name" value="contact" />     <div>
                            <label>Your name:</label><input type="text" name="name" placeholder="Optionally enter your name" />
                            <label className="aog-required">Your email:</label><input type="email" name="email" placeholder="Enter your email address" required />
                            <label className="aog-required">Message:</label><textarea name="message" placeholder="Enter a message" required></textarea>
                        </div>
                        <div>
                            <button className="btn" type="submit">Send</button>
                        </div>
                        </form>
                        <div><p className="small-text"><span className="aog-required">&nbsp;</span> means field is required</p></div>
                </div>
            </section>
        </Layout>
    );
}
